#container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-image: url(../imgs/usina.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.loginHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 72px;
}

.logoLogin {
  width: 12%;
}

.loginHeader a {
  padding: 1% 3%;
  background-color: transparent;
  color: #2d2d2d;
  border: 2px solid #2d2d2d;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

.loginHeader a:hover {
  background-color: #ffffff70;
}

.loginHeader a:active {
  border: 2px solid #d6fa46;
}

#main {
  flex: 1;
  display: flex;
  align-items: center;
}

#hero {
  background-color: #d6fa4691;
  margin: 0 72px;
  padding: 64px 80px 64px;
  border-radius: 20px;
  width: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  gap: 64px;

  transition: opacity 0.5s ease;
}

.hide {
  opacity: 0;
  transform: translateY(-50px);
}

.show {
  transform: translateY(0);
}

#title {
  font-size: 28px;
  letter-spacing: 10px;
  color: #2d2d2d;

  transition: opacity 2s, transform 1s;
}

#strong {
  font-size: 72px;
  letter-spacing: 27px;
  color: #000000;
  transition: opacity 2s, transform 1s;
}

#login {
  background-color: #d6fa46c2;
  padding: 64px 100px 64px;
  border-radius: 20px;
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

#login h2 {
  font-size: 48px;
  color: #2d2d2d;
}

#inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#inputs h3 {
  font-size: 10px;
  color: #2d2d2d;
}

#inputs input {
  border: none;
  border-radius: 4px;
  height: 32px;
  padding: 0px 8px;
}

#login button {
  padding: 12px 32px;
  background-color: #2d2d2d;
  color: #d6fa46;
  letter-spacing: 2px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}

#login button:hover {
  background-color: #5a5a5a;
}

#login button:active {
  background-color: #202020;
}
