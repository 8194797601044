#homeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 72px;

  background-color: #2d2d2d;
  box-shadow: 0 9px 7.3px 2px #0000004d;
}

.logoHome {
  width: 12%;
}

#nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.nav-item {
  color: #fff;
  text-decoration: none;
}

.active {
  color: #d5fa46;
  font-weight: bold;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

#mainHome {
  padding: 56px 72px;
}

#mainHome h1 {
  font-size: 48px;
}

#mainHome h2 {
  color: #2d2d2d;
  font-weight: 400;
  font-size: 24px;
}

#mainHome .card {
  background-image: url(../imgs/iluminacao.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 300px;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;

  display: flex;
  align-items: end;
  justify-content: center;

  box-shadow: 0 4px 12px 0 #2d2d2d;
}

#mainHome .card-title {
  background-color: #fff;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: -2px -8px 40px 12px #000;
}
